<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tablaCalculoNomina"
                        :tableName="'Precálculo de nómina'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsEmpresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo de nómina"
                                        :items="itemsTiposNominas"
                                        item-text="claveDescripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="tipo_nomina_id"
                                        class="pa-0 ma-0"
                                        id="tipoNominaID"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <v-autocomplete
                                        outlined
                                        label="Periodo"
                                        :items="itemsPeriodos"
                                        item-text="numero"
                                        item-value="id"
                                        persistent-hint
                                        v-model="periodo_value"
                                        class="pa-0 ma-0"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoIngreso">
                                    <span>Rango de fechas</span>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicio"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.clave_tipo_nomina}} - {{item.descripcion}}</div></td>
                                <td><div>{{item.numero_periodo}}</div></td>
                                <td><div>{{dateFormat(item.fecha_de_pago, 'tabla')}}</div></td>
                                <td style="min-width:290px!important; max-width:301px!important; width:300px; padding-left:8px; padding-right:8px;">
                                    <div class="tblOpciones justify-center align-center">
                                        <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                    v-if="item.generado"
                                                    @click="detallePrecalculo(item)"
                                                >
                                                    <v-list-item-title>Detalle del precálculo</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.generado == false"
                                                    @click="abrirModalCalcular(item, 'CALCULAR')"
                                                >
                                                    <v-list-item-title>Calcular</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.generado"
                                                    @click="abrirModalCalcular(item, 'RECALCULAR')"
                                                >
                                                    <v-list-item-title>Recalcular</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.generado && item.requiere_recalcular == false"
                                                    @click="abrirModalTimbre(item)"
                                                >
                                                    <v-list-item-title>Acumular</v-list-item-title>
                                                </v-list-item>

                                                <v-list-item
                                                    v-if="item.generado"
                                                    @click="descargarReporteEmpleados(item, 'reporte', 'RECALCULAR')"
                                                >
                                                    <v-list-item-title>Reporte Empleados</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.generado == false"
                                                    @click="abrirModal('update', item)"
                                                >
                                                    <v-list-item-title>Editar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="rol == 'root' || datosLogin.cliente.odesa == true"
                                                    @click="odessaMoper(item)"
                                                >
                                                    <v-list-item-title>MOPER ODESSA</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="rol == 'root' || datosLogin.cliente.odesa == true"
                                                    @click="odessaConfirmacion(item)"
                                                >
                                                    <v-list-item-title>ODESSA confirmación</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="item.requiere_recalcular">
                                            <template v-slot:activator="{ on }">
                                                <v-img
                                                    v-on="on"
                                                    class="mr-n5"
                                                    style="margin-bottom:2px"
                                                    max-height="23"
                                                    max-width="23"
                                                    src="/static/svg/signoExclamacion.svg"
                                                ></v-img>
                                            </template>
                                            <span>Los complementos de esta nómina han <br>sido modificados, es necesario recalcular.</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card" scrollable id='volver-arriba'>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background:#FFF">
                        <v-card class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>
                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoading">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Clientes" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Clientes"
                                                        :items="itemsClientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="12" md="12" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    :items="itemsEmpresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Empresa"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                ></v-autocomplete>
                                            </v-col>
                                            
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Tipo de nómina" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo de nómina"
                                                        :items="itemsTiposNominas"
                                                        item-text="claveDescripcion"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="tipo_nomina_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                        id="tipoNominaID"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Periodo" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Periodo"
                                                        :items="itemsPeriodos"
                                                        item-text="numero"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="calculoNom.periodo_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Fecha de pago" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        v-model="fechaPago"
                                                        label="Fecha de pago"
                                                        placeholder="dd/mm/aaaa"
                                                        :error-messages="errors" 
                                                        required
                                                        class="ma-0 pa-0"
                                                        disabled
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                <v-radio-group v-model="incidencias_checador" row>
                                                    <template v-slot:label>
                                                        <div class="radio_group_01" style="width:220px !important;">Faltas de reloj checador y vacaciones de GDTH</div>
                                                    </template>
                                                    <v-radio label="SI" value="SI"></v-radio>
                                                    <v-radio label="NO" value="NO"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                <v-radio-group v-model="incidencias_capturadas" row>
                                                    <template v-slot:label>
                                                        <div class="radio_group_02" style="width:220px !important;">Incluir complementos</div>
                                                    </template>
                                                    <v-radio label="SI" value="SI"></v-radio>
                                                    <v-radio label="NO" value="NO"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <!--
                                            <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                <v-radio-group v-model="modificaciones_adicionales" row>
                                                    <template v-slot:label>
                                                        <div class="radio_group_03">Modificaciones adicionales</div>
                                                    </template>
                                                    <v-radio label="SI" value="SI"></v-radio>
                                                    <v-radio label="NO" value="NO"></v-radio>
                                                </v-radio-group>
                                            </v-col>

                                            <template v-if="modificaciones_adicionales=='SI'">
                                                <h3 class="subtitle mt-7 ml-5 mb-3">Modificaciones adicionales</h3>

                                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa facturadora" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Empresa facturadora"
                                                            :items="itemsEmpresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="empresa_id"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors" 
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <v-text-field 
                                                        outlined
                                                        label="Comisión" 
                                                        type="number"
                                                        min="0"
                                                        max="100"
                                                        suffix="%"
                                                        class="pa-0 ma-0"
                                                        onkeyup="if(this.value > 100 || this.value<0) this.value = 0;"
                                                        v-model="comision"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <v-text-field 
                                                        outlined
                                                        label="IVA" 
                                                        type="number"
                                                        min="0"
                                                        max="100"
                                                        suffix="%"
                                                        class="pa-0 ma-0"
                                                        onkeyup="if(this.value > 100 || this.value<0) this.value = 0;"
                                                        v-model="iva"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                    <v-radio-group v-model="retencion_iva" row>
                                                        <template v-slot:label>
                                                            <div class="radio_group_04">Aplicar retención de IVA</div>
                                                        </template>
                                                        <v-radio label="SI" value="SI"></v-radio>
                                                        <v-radio label="NO" value="NO"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                    <v-radio-group v-model="retencion_isr" row>
                                                        <template v-slot:label>
                                                            <div class="radio_group_05">Aplicar retención de ISR</div>
                                                        </template>
                                                        <v-radio label="SI" value="SI"></v-radio>
                                                        <v-radio label="NO" value="NO"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </template>-->
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                        v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                        
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

            <!----------modal calcular --------->
            <template class="borde-card">
                <v-dialog v-model="dialogCalculo" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalCalculo"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagenCalculo" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModalCalculo }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingCalculo">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingCalculo" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row class="pa-0 ma-0">
                                    <template>
                                        <v-col xs="12" sm="12" md="12" class="pa-0 ">
                                            <VuetifyMultipleAutocomplete
                                                outlined
                                                :items="items_empleados"
                                                :valorDefecto="modelo_empleados"
                                                item_text="nombre_completo"
                                                item-value="id"
                                                @dataMultiple="(data)=> modelo_empleados = data"
                                                label="Todos los empleados"
                                                ref="multEmpleados"
                                            />
                                            <!-- <v-autocomplete
                                                v-model="modelo_empleados"
                                                :items="items_empleados"
                                                outlined
                                                item-text="nombre_completo"
                                                item-value="id"
                                                label="Todos los empleados"
                                                multiple
                                                @change="cambio_emp()"
                                            >
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>
                                                        {{
                                                            item.nombre_completo < 9
                                                            ? item.nombre_completo
                                                            : item.nombre_completo.substr(0, 8) + "..."
                                                        }}
                                                    </span>
                                                </v-chip>
                                                <span
                                                    v-if="index === 1"
                                                    class="grey--text caption"
                                                >(+{{ modelo_empleados.length - 1 }} otros)
                                                </span>
                                            </template>
                                            </v-autocomplete> -->
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion pt-0">
                            <button class="btnCerrar" @click="cerrarModal()" :disabled="btnCerrar">Cerrar</button>
                            <v-btn class="btnGuardar" @click="descargarReporte(itemCalculo,'calcular', accionCalculo)" :disabled="invaliBtnGuardar" :loading="btnGuardar">Guardar</v-btn>
                            <v-btn class="btnGuardar" @click="consultar()" :disabled="invalidBtnConsultar" :loading="btnConsultar">Consultar</v-btn>        
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogCalculoTabla" persistent max-width="700px" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalCalculoTabla"></v-btn>
                    </template>
                    
                        <v-card class="borde-card">
                            <perfect-scrollbar>
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagenCalculoTabla" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModalCalculoTabla }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text >
                                    <div id="padre" v-show="isLoadingCalculoTabla">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingCalculoTabla" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row class="pa-0 ma-0"> 
                                            <v-row class="pa-0 ma-0 informacionGeneral">
                                                <v-row class="px-9 pt-12 pb-7">
                                                    <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                        <p class="informacionGeneralTitulo">Información global</p>
                                                    </v-col> 
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                        <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Tipo de nómina</p>
                                                        <p class="informacionGeneralText">{{periodicidad}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de empleados:</p>
                                                        <p class="informacionGeneralText">{{totalEmpleados}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha inicial del periodo:</p>
                                                        <p class="informacionGeneralText">{{dateFormat(periodoFechaInicial, 'modal')}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha final del periodo</p>
                                                        <p class="informacionGeneralText">{{dateFormat(periodoFechaFinal, 'modal')}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                        <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                        <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Total de otros pagos:</p>
                                                        <p class="informacionGeneralText">{{formatNum(totalOtrosPagos)}}</p>
                                                    </v-col>
                                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                        <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                        <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                                    </v-col>
                                                </v-row>
                                            </v-row>
                                                
                                            <simple-table
                                                class="tablaWidth"
                                                :rows="itemsEmpleados"
                                                :columns="headersTableEmpleados"
                                                :perPage="[10, 25, 50, 100]"
                                                shortPagination
                                                :modalTable="true"
                                            >
                                                <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                                    <tr :key="item.id" v-for="(item, index) in data">
                                                        <td class="pa-0">
                                                            <expandable-item :image="urlAvatar + item.empleado.foto" :title="item.empleado.nombre_completo" :valid="item.nomina_valida">
                                                                <template slot="content">
                                                                    <table class="tbl-child" width="100%">
                                                                        <tbody>
                                                                            <template>
                                                                                <tr>
                                                                                    <td class="pt-4"><p class="subtitle">Días del periodo: </p></td>
                                                                                    <td class="pt-4"><p class="text">{{item.empleado.dias_periodo}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Días de pago: </p></td>
                                                                                    <td><p class="text">{{item.empleado.dias_de_pago}}</p></td>
                                                                                </tr>
                                                                                <tr v-if="item.empleado.dias_vacaciones != 0">
                                                                                    <td><p class="subtitle">Días de vacaciones: </p></td>
                                                                                    <td><p class="text">{{item.empleado.dias_vacaciones}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Faltas: </p></td>
                                                                                    <td><p class="text">{{item.empleado.faltas}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Incapacidades: </p></td>
                                                                                    <td><p class="text">{{item.empleado.incapacidades}}</p></td>
                                                                                </tr>
                                                                                <template v-if="item.itemsPercepciones.length != 0">
                                                                                    <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    <tr>
                                                                                        <td class="pb-4" style="color: #1E2245; font-weight: bold;">Percepciones:</td>
                                                                                    </tr>
                                                                                    <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                    </tr>
                                                                                    <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                </template>

                                                                                <template v-if="item.itemsDeducciones.length != 0">
                                                                                    <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    <tr>
                                                                                        <td class="pb-4" style="color: #1E2245; font-weight: bold;">Deducciones:</td>
                                                                                    </tr>
                                                                                    <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                    </tr>
                                                                                    <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                </template>

                                                                                <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                    <tr v-if="item.itemsDeducciones.length == 0 && item.itemsPercepciones.length == 0"><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    <tr>
                                                                                        <td class="pb-4" style="color: #1E2245; font-weight: bold;">Otros pagos:</td>
                                                                                    </tr>
                                                                                    <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                    </tr>
                                                                                    <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                </template>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Total percepciones: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Total deducciones: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Total otros pagos: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.otros_ingresos)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.per_excentas)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                    <td><p class="text">{{formatNum(item.per_grabado)}}</p></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="py-4" style="color: #1E2245; font-weight: bold;">Neto a recibir:</td>
                                                                                    <td class="py-4 textNeto" style="color: #1E2245; font-weight: bold;">{{formatNum(item.neto_pagar)}}</td>
                                                                                </tr> 
                                                                            </template>
                                                                        </tbody>
                                                                    </table>
                                                                </template>
                                                            </expandable-item>

                                                            <hr style="border: 1.5px solid #F2F2F2;" v-if="index < data.length - 1" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </simple-table>
                                        </v-row>  
                                    </v-container>
                                </v-card-text>
                            </perfect-scrollbar>
                            <v-card-actions>
                                <v-layout row>
                                    <v-flex class="justify-center">
                                        <div class="btnModal">
                                            <button class="btnCerrar modal-pull-right" @click="cerrarModalTabla()">Cerrar</button>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogDownload" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalDownload"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="justify-center">
                            <div class="headerModal">
                                <img :src="imagenDownload" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModalDownload }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text>
                            <div class="loading">
                                <lottie :options="defaultOptions" :height="150" :width="150"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogTimbre" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalTimbre"></v-btn>
                    </template>
                    <v-card class="borde-card">
                            <v-card-title class="justify-center align-center text-center card_titulo">
                                <div>
                                    <img :src="imagen" class="imgModal pb-5" />
                                    <h2 class="titleModalTimbre pb-5">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <v-container  grid-list-md id="contenedor">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 justify-center align-center text-center textTimbre">
                                        ¿Estás seguro que deseas acumular y timbrar el cálculo de nómina? No se podrá realizar ningún movimiento posteriormente.
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="card_accion pt-0">
                                <v-container  grid-list-md class="ma-0 pa-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="acumular('acumular')"
                                        >
                                            Acumular
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 py-2 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="acumular('timbrar')"
                                        >
                                            Acumular y timbrar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pt-2 pb-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#828282'
                                            @click="cerrarModal()"
                                            text
                                        >
                                            Cancelar
                                        </v-btn>
                                    </v-col>
                                </v-container>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>




        <template class="borde-card">
            <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                </template>
                <v-card class="borde-card">
                    <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                            <v-btn
                                @click="cerrarModalErrores()"
                                small
                                slot="activator"
                                icon
                                text
                                class="v-btn-cerrar modal-pull-right"
                                absolute
                                top
                                right
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        <div class="headerModalStart">
                            <h2 class="titleModalErrores">Timbres requeridos</h2>
                        </div>
                    </v-card-title>

                    <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                        <div id="padre" v-show="isLoading">
                            <div id="loading">
                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                            </div>
                        </div>
                        
                        <v-container v-show="!isLoading" grid-list-md id="contenedor">
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                    <table class="tbl-errores">
                                        <thead class="tbl-header">
                                            <tr>
                                                <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Registro patronal</th>
                                                <th class="errorth">Requeridos</th>
                                                <th style="border-top-right-radius: 20px;" class="errorth">Disponibles</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="erroresRP.length == 0" >
                                                <td class="emptyTableErrores" colspan="3">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(error, index) in erroresRP">
                                                <template>
                                                    <tr :key="index">
                                                        <td class="pl-7 errortd pt-4 pb-0">
                                                            <div class="textoTablaError01">{{error.registro_patronal}}</div>
                                                        </td>
                                                        <td class="errortd"> <div class="textoTablaError01">{{error.requeridos}}</div></td>
                                                        <td class="errortd"> <div class="textoTablaError03">{{error.disponibles}}</div> </td>
                                                    </tr>
                                                    
                                                    <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>


        
        
    </div>
</template>

<script>
import Vue from 'vue';
import Axios from 'axios';
import Datatable from '@/components/datatable/Datatable';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem from '@/components/ExpandableItem.vue';
import queries from '@/queries/CalculoNomina';
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import apiClientes from "@/api/clientes";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiPeriodos from '@/api/nominas/periodos';
import apiEmpresas from '@/api/empresas'
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import LoadingCliker from '@/components/LoadingCliker';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';


export default {
    components: {
        'data-table': Datatable,
        "simple-table": DatatableSimple,
        VuetifyDatePicker,
        ExpandableItem,
        'lottie': Lottie,
        'loading-cliker':LoadingCliker,
        VuetifyMultipleAutocomplete,
    },
    data() {
        return {
            datosLogin      : null,
            rol             : null,
            urlImg          : null,
            url             : "calculo-nomina/listado",
            columns         : [
                {
                    label: 'Tipo nómina',
                    name: 'clave',
                    filterable: false
                },
                {
                    label: 'Periodo',
                    name: 'numero',
                    filterable: false
                },
                {
                    label: 'Fecha de pago',
                    name: 'fecha_de_pago',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {
                activo    :true,
                paginate  :true,
                order     :"desc",
                cliente_id: null,
                acumulado : false,
                empresa_id: null,
                tipo_periodo_especial: false
            },
            tableData       : {},
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            calculoNom                      : {
                id                          : null,
                tipo_nomina_id              : null,
                periodo_id                  : null,
                incidencias_checador        : false,
                incidencias_capturadas      : false,
                modificaciones_adicionales  : false,
                acumulado                   : false,
                modificaciones_campos       : {
                    empresa_id              : null,
                    comision                : 0,
                    iva                     : 0,
                    aplica_retencion_iva    : false,
                    aplica_retencion_isr    : false,
                },
            },
            userData                : this.$session.get('usuario'),
            accion                  : null,
            tituloModal             : null,
            dialog                  : false,
            isLoading               : false,
            isSaving                : false,
            imagen                  : '',
            items_empleados         : [],
            modelo_empleados        : [],
            itemsTiposNominas       : [],
            tipo_nomina_id          : null,
            itemsPeriodos           : [],
            calendar_pago           : false,
            incidencias_checador  : "NO",
            incidencias_capturadas      : "NO",
            modificaciones_adicionales  : "NO",
            empresa_id              : null,
            itemsEmpresas           : [],
            comision                : 0,
            iva                     : 0,
            retencion_iva           : "NO",
            retencion_isr           : "NO",
            itemsClientes           : [],
            cliente_value           : null,
            empresa_value           : null,
            periodo_value           : null,
            fechaPago               : null,
            fecha_inicio            : null,
            fecha_final             : null,
            cambio_empleado         : false,
            imagenAvatar            : "/static/avatar/usuario.jpg",
            showGuardar             : true,
            isLoadingCalculo        : true,
            dialogCalculo           : false,
            imagenCalculo           : '',
            tituloModalCalculo      : 'Precálculo de nómina',
            itemCalculo             : null,
            isLoadingCalculoTabla   : true,
            dialogCalculoTabla      : false,
            imagenCalculoTabla      : '/static/icon/consultar-calculo.png',
            tituloModalCalculoTabla : 'Resultados de la consulta',
            urlAvatar               : null,
            itemsEmpleados          : [],
            headersTableEmpleados   : [
                { label: "Empleados", align:'center',   name: "nombre_completo",    filterable: false },
            ],
            invalidBtnConsultar     : false,
            invaliBtnGuardar        : false,
            btnConsultar            : false,
            btnGuardar              : false,
            btnCerrar               : false,
            dialogDownload          : false,
            imagenDownload          : "/static/icon/catalogos.png",
            tituloModalDownload     : "Descargando archivo",
            defaultOptions          : { animationData: animationData },
            loadingLottie           : false,
            fraseLoading            : 'Timbrando',
            accionCalculo           : 'CALCULAR',
            updatePeriodo           : false,
            dialogTimbre            : false,
            itemPrecalculo          : null,
            totalPercepciones       : 0,
            totalDeducciones        : 0,
            totalOtrosPagos         : 0,
            totalEmpleados          : 0,
            nombreEmpresa           : null,
            periodicidad            : null,
            periodoFechaInicial     : null,
            periodoFechaFinal       : null,
            neto                    : null,
            dialogErrores           : false,
            erroresRP               : [],
            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        }
    },
    watch: {
        /**
         * @function cliente_value Se ejecuta cuando hay un cambio en la variable cliente_value.
         * @description Se ejecuta cuando hay un cambio en la variable cliente_value, se ejecuta los metodos getTiposNominas() y getEmpresas()
         * @param {int} val Es el id de cliente (registro) seleccionado y se le envia al método getEmpresas().
         * @param {int} oldVal Es el id de cliente antiguo.
         */
        cliente_value: function(val, oldVal){
            if(val!= null){
                if(val!=oldVal && oldVal!=null) {
                    this.tipo_nomina_id = null;
                }
                //this.getTiposNominas(); 
                this.getEmpresas(val);
            }
        },
        /**
         * @function tipo_nomina_id Se ejecuta cuando hay un cambio en la variable tipo_nomina_id.
         * @description Se ejecuta cuando hay un cambio en la variable tipo_nomina_id, se ejecuta el metodo getPeriodos()
         * @param {int} val Es el id del tipo de nómina (registro) seleccionado y se le envia al método getPeriodos(val).
         * @param {int} oldVal Es el id de tipo de nómina antiguo.
         */
        empresa_value: function(val, oldVal){
            if(val!= null){
                this.getTiposNominas();
            }
        },
        tipo_nomina_id: async function(val, oldVal){
            if(val != null){
                if(val!=oldVal && oldVal!=null) {
                    this.calculoNom.periodo_id = null;
                }

                if(this.accion == 'add'){
                    this.updatePeriodo = true;
                }
                if(this.accion == 'update'){
                    this.updatePeriodo = (oldVal!=null) ? true:false
                }
                //this.updatePeriodo = (oldVal!=null) ? true:false
                await this.getPeriodos(val);
            } 
        },
        modificaciones_adicionales: function(val){
            if(val == 'NO'){
                document.getElementById("tipoNominaID").focus();
            }
        },
        'calculoNom.periodo_id': function(val, oldVal){
            if( val != null ){
                if(oldVal != null){
                    if(this.itemsPeriodos.length != 0){ 
                        let per = this.itemsPeriodos.find( elemento => elemento.id == val )
                        this.fechaPago = this.dateFormat(per.fecha_de_pago, 'modal');
                    }
                }
            }
        },

    },
    methods: {
        /**
         * @method abrirModal Este método realiza la acción para abrir un modal.
         * @description Este método realiza la acción para abrir un modal dependiento de la acción que se le envie, puede ser 'add' o 'update'.
         * @param {string} accion Esta variable puete tomar el valor de 'add' o 'update'.
         * @param {Object} data Esta variable es un objeto, son los datos de un registro para el calculo de nómina.
        */
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoading  = true;
            if(this.rol == 'root'){
                this.getClientes();
            }
            else{
                this.cliente_value =  this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            
            if(accion == "add"){
                this.tituloModal    = "Precálculo de nómina";
                this.imagen         = "/static/modal/preCalculoNominaCreate.svg";
                
            } 
            else {
                if(data.generado){
                    this.showGuardar = false;
                }
                else{
                    this.showGuardar = true;
                }

                this.tituloModal    = "Actualizar precálculo de nómina";
                this.imagen         = "/static/modal/preCalculoNominaUpdate.svg";
                this.calculoNom.id  = data.id
                this.cliente_value  = data.cliente_id;
                this.empresa_value  = data.empresa_id; 
                this.tipo_nomina_id = data.tipo_nomina_id;
                this.calculoNom.periodo_id = data.periodo_id;
                this.fechaPago = this.dateFormat(data.fecha_de_pago, 'modal');

                if(data.incidencias_checador == true){
                    this.incidencias_checador = 'SI';
                }
                else{ 
                    this.incidencias_checador = 'NO'; 
                }

                if(data.incidencias_capturadas == true){
                    this.incidencias_capturadas = 'SI';
                }
                else{
                    this.incidencias_capturadas = 'NO';
                }

                if(data.modificaciones_adicionales == true){
                    this.modificaciones_adicionales = 'SI'
                }
                else{
                    this.modificaciones_adicionales = 'NO'
                }

                if(data.modificacionAdicional != null){
                    this.comision = data.modificacionAdicional.comision;
                    this.iva = data.modificacionAdicional.iva;
                    this.empresa_id = data.modificacionAdicional.empresa_id;
                    if(data.modificacionAdicional.aplica_retencion_iva == true){
                        this.retencion_iva = 'SI';
                    }
                    else{
                        this.retencion_iva = 'NO';
                    }

                    if(data.modificacionAdicional.aplica_retencion_isr == true){
                        this.retencion_isr = 'SI';
                    }
                    else{
                        this.retencion_isr = 'NO';
                    }
                }    
            }
            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },

        /**
         * @method cerrarModal Este método cierra el modal.
         * @description Este método cierra el modal en cuestion y llama al metodo resetValues() para resetear los valores.
         */
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.dialogCalculo =  false;
            this.dialogTimbre = false;
            this.resetValues();
        },

        /**
         * @method resetValues Este método resetea los valores.
         * @description Este método resetea los valores por defecto de modales y variables para tener un modal limpio a la hora de abrirlos de nuevo.
         */
        async resetValues() {
            this.calculoNom                 = {
                id                          : null,
                tipo_nomina_id              : null,
                periodo_id                  : null,
                incidencias_checador        : false,
                incidencias_capturadas      : false,
                modificaciones_adicionales  : false,
                acumulado                   : false,
                modificaciones_campos       : {
                    empresa_id              : null,
                    comision                : 0,
                    iva                     : 0,
                    aplica_retencion_iva    : false,
                    aplica_retencion_isr    : false,
                },
            },
            this.incidencias_checador  = "NO";
            this.incidencias_capturadas      = "NO";
            this.modificaciones_adicionales  = "NO";

            this.empresa_id         = null;
            this.cliente_value      = null;
            this.itemsEmpresas      = [];
            this.comision           = 0;
            this.iva                = 0;
            this.retencion_iva      = "NO";
            this.retencion_isr      = "NO"; 
            this.fechaPago          = null;
            this.tipo_nomina_id     = null;
            this.items_empleados    = [];
            this.modelo_empleados   = [];
            this.itemsTiposNominas  = [];
            this.itemsPeriodos      = [];
            this.showGuardar        = true;
            this.accionCalculo      = 'CALCULAR';
            this.itemPrecalculo     = null;


            this.itemsClientes = [];
            this.empresa_value = null;
            this.periodo_value = null;
            this.fecha_inicio = null;
            this.fecha_final  = null;
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }

            this.cambio_empleado = false;
            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },
        async loadModalData(){
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } 
            else if(this.rol == "admin"){
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },

        /**
         * @method setFilters Este método se ejecuta despues de dar clic en el botón buscar
         * @description Este método se ejecuta despues de dar clic en el botón buscar, se envian los filtros que se indicaron en el modal búsqueda. Y se reflejan en la tabla principal.
         * Se ejecuta como emit del componente tabla.
         */
        setFilters() {
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let tipo_nomina = this.tipo_nomina_id;
            let periodo_value = this.periodo_value;
            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            

            if (this.rol !== "root") {
                cliente = this.datosLogin.cliente_id;
                if (this.rol !== "admin") {
                    empresa = this.datosLogin.empresa_id;
                } 
            } 

            let filterParams = {
                cliente_id: cliente,
                empresa_id: empresa,
                tipo_nomina_id: tipo_nomina,
                periodo_id: periodo_value,
                fecha_inicio: fecha_inicio,
                fecha_final: fecha_final
            };

            let defaultParams = {
                activo    :true,
                paginate  :true,
                order     :"asc",
                acumulado : false,
                tipo_periodo_especial: false
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;
            this.cliente_value = null;
            this.itemsEmpresas = [];
            this.empresa_value = null;
            this.itemsTiposNominas = [];
            this.tipo_nomina_id = null;
            this.itemsPeriodos = [];
            this.periodo_value = null;
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
        },

        /**
         * @method eliminar Este método elimina el registro para el cálculo de nómina de un periodo.
         * @description Este método elimina el registro para el cálculo de nómina de un periodo y actualiza la tabla en caso de que la petición sea correcta.
         * @param {Object} data Esta variable es un objeto, son los datos del registro para el cálculo de nómina.
         */
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el cálculo de nómina " + data.clave_tipo_nomina + " - " + data.descripcion + "?",
                "Eliminar",
                () => {
                    apiCalculoNomina.delete(data.id).then(response => {
                        Notify.Success("Cálculo de nómina eliminado", "El cálculo de nómina ha sido eliminado satisfactoriamente");
                        this.$refs.tablaCalculoNomina.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },

        /**
         * @method abrirModalTimbre Este método realiza la acción para abrir un modal de alerta para realizar la "acumulación" o "acumulación y timbrado".
         * @description Este método realiza la acción para abrir un modal de alerta para realizar la "acumulación" o "acumulación y timbrado".
         * @param {Object} item Esta variable es un objeto, son los datos de un registro para el cálculo de nómina.
        */
        abrirModalTimbre(item){
            this.imagen = "/static/icon/alert_warning.png";
            this.tituloModal = "ADVERTENCIA"
            this.dialogTimbre =  true;
            this.itemPrecalculo = item;
        },

        /**
         * @method acumular Este método realiza la acción de acumular el cálculo de nómina del periodo.
         * @description Este método realiza la acción de acumular el cálculo de nómina del periodo. Se envia un parametro "accion" que puede tomar el valor de 'acumular' o 'timbrar'.
         * -Si la accion='acumular' se realiza la petición de acumular y se actuliza la tabla en caso de que la petición sea correcta.
         * -Si la accion='timbrar' se realiza la petición de acumular y en caso de que la petición sea correcta se realiza la petición del timbrado de nómina, seguido se actualiza la tabla. 
         * @param {string} accion Esta variable es un string, le valor puede ser 'acumular' o 'timbrar'.
        */
        async acumular(accion){
            this.loadingLottie = true;
            this.fraseLoading = "Acumulando";
            let self = this;
            if (accion == 'acumular'){
                let param = {nomina_id:self.itemPrecalculo.id};
                apiCalculoNomina.acumular(param).then(async response => {
                    Notify.Success("Acumulado de nómina", "El cálculo de nómina ha sido acumulado correctamente."); 
                    this.loadingLottie = false;
                    self.$refs.tablaCalculoNomina.getData();
                    self.cerrarModal();
                })
                .catch(err => {
                    this.loadingLottie = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        self.cerrarModal();
                    }
                });
            }
            else if (accion == 'timbrar'){
                let param = {nomina_id:self.itemPrecalculo.id};
                apiCalculoNomina.acumularTimbrar(param).then(async response => {
                    if(response.status == 200){
                        self.detalleTimbre(self.itemPrecalculo);
                        self.$refs.tablaCalculoNomina.getData();
                    }
                    else if(response.status == 202){
                        self.erroresRP = response.data.items;
                        this.dialogErrores = true;
                    }
                    this.loadingLottie = false;
                    
                })
                .catch(err => {
                    this.loadingLottie=false; 
                    this.$refs.tablaCalculoNomina.getData();
                    this.cerrarModal();
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;
                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            }
        },

        /**
         * @method timbrar Este método realiza la acción de timbrar el cálculo de nómina del periodo.
         * @description Este método realiza la acción de timbrar el cálculo de nómina del periodo.
         * @param {Object} data Esta variable es un objeto que contine los datos del registro de cálculo de nóminas
        */
        timbrar(data){
            this.fraseLoading = "Timbrando";
            this.loadingLottie = true;
            let param = {nomina_id:data.id};
            apiCalculoNomina.timbrar(param).then(response => {
                this.detalleTimbre(data);
            })
            .catch(err => {
                this.loadingLottie=false; 
                this.$refs.tablaCalculoNomina.getData();
                this.cerrarModal();
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });        
        },
        
        /**
         * @method guardar Este método guarda los datos que se necesitan para un cálculo de nómina de un periodo.
         * @description Este método guarda los datos que se necesitan para un cálculo de nómina de un periodo. 
         * El método se llama para crear o actulizar un registro para el cálculo de nómina.
         */
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.incidencias_checador == 'SI'){
                    this.calculoNom.incidencias_checador = true;
                }
                else{
                    this.calculoNom.incidencias_checador = false;
                }

                if(this.incidencias_capturadas == 'SI'){
                    this.calculoNom.incidencias_capturadas = true;
                }
                else{
                    this.calculoNom.incidencias_capturadas = false;
                }

                if(this.modificaciones_adicionales == 'SI'){
                    this.calculoNom.modificaciones_adicionales = true;
                }
                else{
                    this.calculoNom.modificaciones_adicionales = false;
                }

                if(this.retencion_iva == 'SI'){
                    this.calculoNom.modificaciones_campos.aplica_retencion_iva = true;
                }
                else{
                    this.calculoNom.modificaciones_campos.aplica_retencion_iva = false;
                }

                if(this.retencion_isr == 'SI'){
                    this.calculoNom.modificaciones_campos.aplica_retencion_isr = true;
                }
                else{
                    this.calculoNom.modificaciones_campos.aplica_retencion_isr = false;
                }
                
                this.calculoNom.modificaciones_campos.empresa_id =  this.empresa_id;
                this.calculoNom.modificaciones_campos.comision =  this.comision;
                this.calculoNom.modificaciones_campos.iva =  this.iva;
                this.calculoNom.tipo_nomina_id = this.tipo_nomina_id;

                let empleados = [];
                if(this.accion === "add") {
                    /* this.modelo_empleados.forEach((element) => {
                        empleados.push({empleado_id: element});
                    }); */
                    //this.calculoNom.empleados = empleados;

                    apiCalculoNomina.store(this.calculoNom).then((response) => {
                        Notify.Success("Operación exitosa", "El cálculo de nómina se guardó satisfactoriamente.");
                        this.$refs.tablaCalculoNomina.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                        this.isSaving = false;
                    });
                }
                else if(this.accion === "update") {
                    /* if(this.cambio_empleado){
                        this.modelo_empleados.forEach((element) => {
                            empleados.push({empleado_id: element});
                        });
                    } else{
                        this.modelo_empleados.forEach((element) => {
                            empleados.push({empleado_id: element["id"]});
                        });
                    }
                    this.calculoNom.empleados = empleados; */
                    apiCalculoNomina.update(this.calculoNom).then((response) => {
                        Notify.Success("Operación exitosa", "El cálculo de nómina se actualizó satisfactoriamente.");
                        this.$refs.tablaCalculoNomina.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        console.log(err)
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                        this.isSaving = false;
                    });
                }
            });
        },

        /**
         * @method getClientes Este método realiza la petición a la api de cliente
         * @description Este método realiza la petición a la api de clientes, este patición sirve para llenar el campo de clientes en la parte de los filtros y en el modal de regristro o actualización.
         */
        async getClientes(){
            let paramClientes = {
                activo      : true, 
                paginate    : false,
                cliente_id  : this.datosLogin.cliente_id
            }
            await apiClientes.getClientes(paramClientes).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los clientes");
            })
        },

        /**
         * @method getTiposNominas Este método realiza la petición a la api de tipos de nómina
         * @description Este método realiza la petición a la api de tipos de nómina y llena el array itemsTiposNominas. 
         * A la api se le envia el id del cliente.
         */
        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                precalculo  : true,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        if(item.periodos > 0){
                            item.claveDescripcion = item.clave + ' - ' + item.descripcion
                            this.itemsTiposNominas.push(item);
                        }
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },

        /**
         * @method getPeriodos Este método realiza la petición a la api de periodos
         * @description Este método realiza la petición a la api de periodos y llena el array itemsPeriodos. 
         * A la api se le envia el id del tipo de nómina.
         */
        async getPeriodos(tipoNomID){
            let param = {
                activo      : true,
                paginate    : false,
                tipo_nomina_id : tipoNomID,
                actual      : true,
            }
            await apiPeriodos.find(param).then((response) => {
                this.itemsPeriodos = response.data.filter(element=>element.tipo_periodo_especial==null);
                if(this.itemsPeriodos.length != 0){
                    if(this.updatePeriodo) {
                        let per = this.itemsPeriodos.find( elemento => elemento.actual == true && elemento.tipo_periodo_especial == null)
                        this.calculoNom.periodo_id = per.id;
                        this.fechaPago = this.dateFormat(per.fecha_de_pago, 'modal');     
                    }
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los periodos");
            })
        },

        /**
         * @method getEmpleados Este método realiza la petición a la api de empleados.
         * @description Este método realiza la petición a la api de empleados que va servir en el modal para agregar los empleados que se quieren cálcular la nómina.
         * @param {Object} datos Objeto que contiene los datos del requistro de cálculo de nómina.
         */
        async getEmpleados(datos) {
            let param = {tipo_nomina_id:datos.tipo_nomina_id, periodo_id:datos.periodo_id, tipo: 'GENERAL'}
            await apiTipoNomina.getEmpleados(param).then((response) => {
                this.items_empleados = response.data;
                if(response.data.length == 0){
                    this.invaliBtnGuardar = true;
                    this.invalidBtnConsultar = true;
                }
                else{
                    this.invaliBtnGuardar = false;
                    this.invalidBtnConsultar = false;
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los empleados.");
            })
        },
        remove (item) {
            const index = this.modelo_empleados.indexOf(item.id)
            if (index >= 0) this.modelo_empleados.splice(index, 1)
        },
        cambio_emp(){
            this.cambio_empleado = true;
        },

        /**
         * @method getEmpresas Este método realiza la petición a la api de empresas.
         * @description Este método realiza la petición a la api de empresas, esta petición sirve para llenar el campo de empresas.
         * @param {int} cliente_id id de cliente, sirve para enviar en los paramentros de la api de empresas.
         */
        async getEmpresas(cliente_id){
            let param = {
                activo      : true, 
                paginate    : false,
                cliente_id  : cliente_id
            }
            await apiEmpresas.getEmpresas(param).then((response) => {
                this.itemsEmpresas = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar las empresas.");
            })
        },

        /**
         * @method descargarReporte Este método descarga un reporte con extensión .xlsx
         * @description Este método realiza la petición a la api calcular y descargamos el reporte con extensión .xlsx
         * @param {Object} item Objeto que contiene los datos del registro del cálculo de nómina.
         * @param {String} tipo tipo de consulta 'reporte' o 'calcular'
         * @param {accion} accion tipo de accion en este caso es RECALCULAR o CALCULAR.
         */
        async descargarReporte(item, tipo, accion){
            let sefl = this;
            this.btnGuardar = true;
            this.invalidBtnConsultar = true;
            this.btnCerrar = true;

            let url = "calculo-nomina/calcular";
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre;
            nombre =  item.clave_tipo_nomina + "-" + item.descripcion + " - Periodo " + item.numero_periodo + ".xlsx";
            let empleados = [];
            this.modelo_empleados.forEach((element) => {
                empleados.push({empleado_id: element});
            });
            let itemCalculoId = null;
            let descargar = false;
            if(tipo == 'reporte'){
                itemCalculoId = item.id;
                descargar = true;
                this.loadingLottie =  true;
                this.fraseLoading = "Descargando";
            }
            else{
                itemCalculoId = this.itemCalculo.id;
                descargar = false;
            }

            let parametros = { id : itemCalculoId, accion: accion, tipo_reporte: "empleados", tipo_nomina : 'GENERAL', empleados : empleados, descargar:descargar }
            const object1 = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object1,
                }).then(function(response){
                    FileDownload(response.data, nombre);
                    sefl.$refs.tablaCalculoNomina.getData();
                    sefl.btnGuardar = false;
                    sefl.invalidBtnConsultar = false;
                    sefl.btnCerrar = false;
                    sefl.loadingLottie =  false;
                    sefl.cerrarModal();
                })
                .catch(async (error) => {
                    //console.log(decodeURIComponent(escape(error.response.headers["content-type"])));
                    //Notify.ErrorToast(decodeURIComponent(escape(error.response.headers["content-type"])));

                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }

                    this.btnGuardar = false;
                    this.invalidBtnConsultar = false;
                    this.btnCerrar = false;
                    this.loadingLottie =  false;
                    this.cerrarModal();
                });
            } catch (err) {
                Notify.ErrorToast("La configuración de la nómina tiene agregar incidencias, pero aun hay periodos de incidencias no cerradas.");
                this.btnGuardar = false;
                this.invalidBtnConsultar = false;
                this.btnCerrar = false;
                this.loadingLottie =  false;
                this.cerrarModal();
            }
        },
        async descargarReporteEmpleados(item, tipo){
            let url             = "reportes/nomina";
            this.loadingLottie  =  true;
            this.fraseLoading   = "Descargando";
            const FileDownload  = require("js-file-download");
            let nombre          = item.nombre_empresa + "-" + item.clave_tipo_nomina + "-" + item.numero_periodo + ".xlsx";
            let parametros = { nomina_id : item.id, tipo_nomina : 'GENERAL' }
            const object1 = parametros;

            try {
                await Vue.axios({
                method: "POST",
                responseType: "blob",
                url: url,
                data: object1,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                });
            } catch (err) {
                this.loadingLottie = false;
                Notify.ErrorToast("No se pudo calcular la nomina");
            }
        },

        /**
         * @method calcular Este método hace la petición a la api de calcular nómina.
         * @description Este método realiza la petición a la api de calcular nómina.
         */
        calcular(item){
            let parametros = { id : item.id , accion: "CALCULAR", tipo_reporte : "empleados", tipo_nomina:"general"  }
            apiCalculoNomina.calcular(parametros).then((response) => {
                Notify.Success("Operación exitosa", "La nómina se calculó satisfactoriamente.");
                this.$refs.tablaCalculoNomina.getData();
            })
            .catch(err => {
                console.log(err)
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                this.isSaving = false;
            });
        },

        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato AAAA-MM-DD
         * @param {string} tipo string con valor 'modal' o 'tabla'
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha, tipo) {
            if(fecha == null){
                return;
            }
            else{
                let fecha2 = fecha.split("-");
                if(tipo == 'modal'){
                    return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
                }else{
                    return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
                }
            }
            
        },

        /**
         * @method abrirModalCalcular Método para abrir el modal para calcular y precalcular (consultar)
         * @description Método para abrir el modal para calcular y precalcular (consultar).
         */
        async abrirModalCalcular(datos, tipoCalculo){
            this.isLoadingCalculo   = true;
            this.accionCalculo = tipoCalculo;
            this.imagenCalculo      = "/static/icon/calcular_nomina.png";
            this.tipoCalculo = tipoCalculo;
            await this.getEmpleados(datos);
            this.itemCalculo =  datos;
            this.nombreEmpresa = datos.nombre_empresa;
            this.periodicidad = datos.periodicidad;
            this.isLoadingCalculo   = false;
            setTimeout(function(){ document.getElementById("btnModalCalculo").click(); }, 100);
        },

        /**
         * @method consultar Método para consultar o reconsultar.
         * @description Método para consultar o reconsultar el registro para el cálculo de nómina. El resultado de la función se muestra en el 
         * modal de precalculo de nomina.
         */
        consultar(){
            let self = this;
            this.isLoadingCalculoTabla = true;
            setTimeout(function(){ document.getElementById("btnModalCalculoTabla").click(); }, 100);
            let empleados = [];
            this.modelo_empleados.forEach((element) => {
                empleados.push({empleado_id: element});
            });
            let tipoConsulta = 'CONSULTAR';
            if(this.tipoCalculo === 'CALCULAR'){
                tipoConsulta = 'CONSULTAR';
            }
            else{
                tipoConsulta = 'RECONSULTAR';
            }
            let parametros = { id : this.itemCalculo.id, accion: tipoConsulta, tipo_reporte: "empleados", tipo_nomina : 'GENERAL', empleados : empleados }
            apiCalculoNomina.calcular(parametros).then((response) => {
                
                //this.itemsEmpleados = response.data;
                self.periodoFechaInicial    = response.periodo.fecha_inicial;
                self.periodoFechaFinal      = response.periodo.fecha_final;
                self.totalPercepciones       = response.totales.percepciones;
                self.totalDeducciones        = response.totales.deducciones;
                self.totalOtrosPagos         = response.totales.otros_pagos;
                self.neto                    = response.totales.neto;
                self.totalEmpleados          = 0;

                let formatEmpleados = response.data;

                if(formatEmpleados.length != 0){
                    self.totalEmpleados    = formatEmpleados.length;
                    formatEmpleados.forEach(empleado => {
                        empleado.itemsPercepciones  = [];
                        empleado.itemsDeducciones   = [];
                        empleado.itemsOtrosPagos    = [];
                        empleado.itemsInformativos  = [];

                        if(parseFloat(empleado.empleado.total_sueldo) != 0){
                            empleado.itemsPercepciones.push({
                                codigo : 'P1000',
                                nombre : 'Sueldo',
                                total  : empleado.empleado.total_sueldo
                            });
                        }
                        empleado.incidencias.forEach(incidencia =>{
                            switch (incidencia.codigo_incidencia.charAt(0)) {
                                case 'P':
                                    let complemento = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsPercepciones.push(complemento);
                                    }
                                    
                                    break;
                                case 'D':
                                    let complemento2 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsDeducciones.push(complemento2);
                                    }
                                    break;
                                case 'O':
                                    let complemento3 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsOtrosPagos.push(complemento3);
                                    }
                                    break;  
                                case 'I':
                                    let complemento4 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsInformativos.push(complemento4);
                                    }
                                    break;  
                                default:
                                    break;
                            }  
                        })

                        if(empleado.ajuste_mensual){
                            empleado.ajuste_mensual.forEach(incidencia =>{
                                switch (incidencia.codigo.charAt(0)) {
                                    case 'P':
                                        let complemento = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsPercepciones.push(complemento);
                                        }
                                        break;
                                    case 'D':
                                        let complemento2 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsDeducciones.push(complemento2);
                                        }
                                        break;
                                    case 'O':
                                        let complemento3 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsOtrosPagos.push(complemento3);
                                        }
                                        break;  
                                    case 'I':
                                        let complemento4 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsInformativos.push(complemento4);
                                        }
                                        break;  
                                    default:
                                        break;
                                }  
                            })
                        }
                        

                        if(parseFloat(empleado.subsidio) != 0){
                            empleado.itemsOtrosPagos.push({
                                codigo : 'O999',
                                nombre : 'Subsidio a entregar',
                                total  : empleado.subsidio
                            });
                        }
                        if(parseFloat(empleado.isr) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D998',
                                nombre : 'ISR',
                                total  : empleado.isr
                            });
                        }
                        if(parseFloat(empleado.imss) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D999',
                                nombre : 'IMSS',
                                total  : empleado.imss
                            });
                        }
                    })
                }

                this.itemsEmpleados = formatEmpleados;
                this.isLoadingCalculoTabla = false;
                if(!response.valid){
                    Notify.Error("ADVERTENCIA","Uno o más empleados presentan problemas en el cálculo de su nómina", null, 'Enterado')
                }
                
                
            })
            .catch(err => {
                console.log(err)
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                this.isSaving = false;
                this.cerrarModalTabla();
            });
        },

        /**
         * @method cerrarModalTabla Método para cerrar modal
         * @description Método que cierra el modal del precalculo de nómina.
         */
        cerrarModalTabla(){
            this.dialogCalculoTabla = false;        
        },

        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        redondearNumero(numero){
            return Math.round(numero*100)/100
        },
        detallePrecalculo(data){
            this.$session.remove("detallePrecalculo");
            this.$session.set('detallePrecalculo',data);
            this.$router.push('/detallePrecalculo');
        },
        detalleTimbre(data){
            this.$session.remove("detalleTimbre");
            this.$session.set('detalleTimbre',data);
            this.$router.push('/detalleTimbre');
        },

        odessaConfirmacion(item){
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            let parametros = {
                periodo_id: item.periodo_id
            }
            let nombre;
            nombre = "ODESSA_confirmacion"+item.nombre_empresa + "_" + item.clave_tipo_nomina + "_" + item.numero_periodo + ".zip";

            let urlCalculo = "odesa/confirmacion";

            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: urlCalculo,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error)=>{
                    console.log(error);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                    
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la descarga");
                console.log(err);
                this.loadingLottie = false;
            }
        },
        odessaMoper(item){
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            let parametros = {
                periodo_id: item.periodo_id
            }
            let nombre;
            nombre = "ODESSA_MOPER"+item.nombre_empresa + "_" + item.clave_tipo_nomina + "_" + item.numero_periodo + ".zip";

            let urlCalculo = "odesa/moper";

            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: urlCalculo,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error)=>{
                    console.log(error);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                    
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la descarga");
                console.log(err);
                this.loadingLottie = false;
            }
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresRP = [];
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("calculo-nomina.store")
            this.permisos.update = permisosStorage.includes("calculo-nomina.update")
            this.permisos.delete = permisosStorage.includes("calculo-nomina.destroy")
        },
    },

    /**
     * @description create(). Se inician los valores que se requieren antes de montar todos los componentes.
    */
    created(){
        this.urlAvatar = GetUrlImg.getURL();
        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        this.permisosVista();
        if (this.rol != "root") {
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.cliente_value = this.datosLogin.cliente_id;
            if(this.rol == 'admin-empresa' || this.rol == 'admin-sucusal' || this.rol == 'admin-depto'){
                this.filters.empresa_id =  this.datosLogin.empresa_id;
            }
        }
        if(this.rol == 'root'){
            this.getClientes();
        }
    },

    /**
     * @description updated(). Se ejecuta cada vez que hay un cambio en el Virtual DOM.
    */
    updated() {    
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.width   = "310px";
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.paddingRight   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.paddingLeft   = "8px";

        if(document.querySelector('#tableScrollSimple')){
            document.querySelector('#tableScrollSimple tbody').style.maxHeight    = "none";
            document.querySelector('#tableScrollSimple tbody').style.minWidth     = "100%";
            document.querySelector('#tableScrollSimple tbody').style.background   = "#fff";
        }
    }

}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .radio_group_01{
        margin-right: 48px;
        margin-left: 5px;
    }
    .radio_group_02{
        margin-right: 48px;
        margin-left: 5px;
    }
    .radio_group_03{
        margin-right: 23px;
        margin-left: 5px;
    }
    .radio_group_04{
        margin-right: 47px;
        margin-left: 5px;
    }
    .radio_group_05{
        margin-right: 47px;
        margin-left: 5px;
    }
    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    .titleModalTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '600';
        font-size: 18px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .textTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '500';
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
        padding-left: 15vh;
    }
    .textNeto{
        padding-left: 15vh;
    }
    #tableScrollSimple tbody{
        max-height:none !important;
        min-width: 100% !important;
        background-color: #fff !important;
    }
    .tbl-child tbody td:first-child {
        padding-left: 35px !important;
        width: 320px;
    }
    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
    }


    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

    #btnModalErrores{
        display: none;
    }
</style>
